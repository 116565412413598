$(document).ready(function () {
	$('.menu').click(function () {
		$('nav').slideDown();
	});
	$('.menu_close').click(function () {
		$('nav').slideUp();
	});

	$('.js-price').click(function () {
		if ($(this).hasClass("open")) {
			$(this).removeClass('open');
			$(this).addClass('close');
			$(this).find('p').slideUp();
		} else {
			$(this).removeClass('close');
			$(this).addClass('open');
			$(this).find('p').slideDown();
		}
	});

	$('a.border').magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		closeBtnInside: false,
		fixedContentPos: true,
		mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
		image: {
			verticalFit: true
		},
		zoom: {
			enabled: true,
			duration: 300 // don't foget to change the duration also in CSS
		}
	});
	
	/*$(".js-modal-inner").magnificPopup({
		disableOn: 560
	});*/
	
	$(".js-modal").magnificPopup();


	$('.slider').slick({
		centerMode: true,
		centerPadding: '200px',
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: false,
		prevArrow: '<div class="arrow left" style="left: 16%;"></div>',
		nextArrow: '<div class="arrow right" style="right: 16%;"></div>',
		arrows: true,
		responsive: [
			{
				breakpoint: 1375,
				settings: {
					prevArrow: '<div class="arrow left" style="left: 15%;"></div>',
					nextArrow: '<div class="arrow right" style="right: 15%;"></div>',
					centerPadding: '160px'
				}
			},
			{
				breakpoint: 560,
				settings: "unslick"
			}
		]
	});


	$('.js-go_to').click(function () { // ловим клик по ссылке с классом go_to
		var scroll_el = $(this).attr('href'); // возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или .
		if ($(scroll_el).length != 0) { // проверим существование элемента чтобы избежать ошибки
			$('html, body').animate({scrollTop: $(scroll_el).offset().top}, 500); // анимируем скроолинг к элементу scroll_el
		}
		return false; // выключаем стандартное действие
	});


	$(function ($) {
		$(".js-phone").mask("(999) 999-9999");
	});


});